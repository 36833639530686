<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-5 col-12">Schemes List</div>
        <div class="
                        col-lg-7 col-12
                        text-end
                        d-flex
                        justify-content-end
                        custom-flex-cloumn-mob
                      ">
            <button type="button" @click="addschemeModalOpen()" class="header-btn-outer btn btn-primary">
                Add Scheme
            </button>
        </div>
    </div>
    <div class="custom-ultima-datatable">
        <DataTable :value="schemeList" :scrollable="true" scrollHeight="flex" columnResizeMode="fit" :paginator="true"
            :lazy="true" :rowHover="true" :totalRecords="totalRecords" :rows="30" dataKey="aj1" @page="changePage($event)"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            :loading="loading">
            <template #empty>No Records found.</template>
            <template #loading>Loading schemes data. Please wait...</template>
            <Column field="Name" header="Scheme Name" headerStyle="width: 60%" bodyStyle="width: 60%">
                <template #body="{ data }">
                    <div>{{ data.schemelabel ? data.schemelabel : "N/A" }}</div>
                </template>
            </Column>
            <Column field="Name" header="Launch Date" headerStyle="width: 15%" bodyStyle="width: 15%">
                <template #body="{ data }">
                    <div>{{ data.schemedate ? data.schemedate : "N/A" }}</div>
                </template>
            </Column>
            <Column field="Name" header="Beneficiaries Count" headerStyle="width: 15%" bodyStyle="width: 15%">
                <template #body="{ data }">
                    <div>{{ data.benifitcount ? data.benifitcount : "N/A" }}</div>
                </template>
            </Column>
            <Column field="action" header="Action" class="justify-content-center" headerStyle="width: 10%"
                bodyStyle="width: 10%">
                <template #body="{ data }">
                    <button type="button" :disabled="data.disabledflag == 1" class="btn custom-view-detail-btn"
                        @click="schemeReportModalOpen()">
                        View
                    </button>
                </template>
            </Column>
        </DataTable>
    </div>
    <!-- Add New Campign modal start here -->
    <div class="modal-mask" v-if="addschememodalsflag">
        <div class="modal-dialog modal-md modal-dialog-centered
            custom-modal-outer">
            <div class="modal-content ">
                <div class="modal-header whatsapp-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Add Scheme</h5>
                    <button type="button" class="btn-close" @click="addschemeModalClose()"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                            <div class="custom-form-group">
                                <label class="form-label">Scheme Name <span class="text-danger">*</span></label>
                                <input type="text" v-model="schemename" class="form-control" placeholder="Enter Scheme Name"
                                    maxlength="50" autocomplete="off" />
                                <div class="custom-error" v-if="v$.schemename.$error">
                                    {{ v$.schemename.$errors[0].$message }}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-12">
                            <div class="custom-form-group">
                                <label for="formBirthDateInput" class="form-label">Scheme Launch Date<span
                                        class="text-danger">*</span></label>
                                <datepicker v-model="schemedate" inputFormat="dd/MM/yyyy" :upperLimit="new Date()"
                                    :typeable="false" :clearable="false" placeholder="DD/MM/YYYY"
                                    class="custom-date-picker form-control" />
                                    <div class="custom-error" v-if="v$.schemedate.$error">
                                    {{ v$.schemedate.$errors[0].$message }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-12 text-end">
                        <button type="button" class="btn modal-submit-btn" @click="createscheme()">
                            <span v-if="!modalloader">Submit</span>
                            <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                                v-if="modalloader">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Add New Campign modal end here -->
</template>
<script>
import ApiService from "../../service/ApiService";
import useValidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

export default {
    data() {
        return {
            v$: useValidate(),
            schemeList: [
                {
                    id: 0,
                    schemelabel: "Financial Incentives for Certification and intellectual property rights reimbursement scheme",
                    schemedate: "17/04/2023",
                    benifitcount: "12,40,290",
                    disabledflag: 0,
                },
                {
                    id: 1,
                    schemelabel: "Interest Subsidy Scheme",
                    schemedate: "01/04/2023",
                    benifitcount: "2,39,874",
                    disabledflag: 1,
                },
                {
                    id: 2,
                    schemelabel: "Incentives to industries for training prospective employees scheme",
                    schemedate: "23/09/2018",
                    benifitcount: "62,04,790",
                    disabledflag: 1,
                },
                {
                    id: 3,
                    schemelabel: "Incentives to encourage purchases from local supplies scheme",
                    schemedate: "01/01/2019",
                    benifitcount: "96,466",
                    disabledflag: 1,
                },
            ],
            totalRecords: 5,
            loading: false,
            addschememodalsflag: false,
            schemename: "",
            schemedate: null,
            modalloader: false,
            schemestatusflag: 0,
            disabledflag: 0,
        };
    },
    validations() {
        return {
            schemename: {
                required: helpers.withMessage("Please enter scheme name", required),
            },
            schemedate: {
                required: helpers.withMessage("Please select scheme date", required),
            }
        };
    },
    ApiService: null,

    created() {
        this.ApiService = new ApiService();
    },

    mounted() {
        // this.loading = true;
        // this.getcampaigntemplates();
    },

    methods: {
        // getcampaigntemplates(item) {
        //     this.ApiService.getcampaigntemplates(item).then((data) => {
        //         if (data.success === true) {
        //             this.schemeList = data.records;
        //             this.totalRecords = data.totalcount;
        //             this.loading = false;
        //         } else {
        //             this.loading = false;
        //             this.schemeList = null;
        //             this.totalRecords = 0;
        //         }
        //     });
        // },
        changePage(event) {
            this.page_no = event.page;
            // this.getcampaigntemplates({ page_no: this.page_no });
        },
        addschemeModalOpen() {
            this.addschememodalsflag = true;
        },
        addschemeModalClose() {
            this.addschememodalsflag = false;
        },
        createscheme() {
            // this.v$.$validate();
            // let fields = {};
            // fields["lk3"] = this.schemename;
            // if (!this.v$.$error) {
            //     this.modalloader = true;
            //     this.ApiService.storecampaigntemplate(fields).then((data) => {
            //         if (data.status == 200) {
            //             this.modalloader = false;
            //             // var successMsg = data.message;
            //             this.addschememodalsflag = false;
            //             // this.$toast.open({
            //             //     message: successMsg,
            //             //     type: "success",
            //             //     duration: 3000,
            //             //     position: "top-right",
            //             // });
            //             setTimeout(() => {
            //                 this.v$.$reset();
            //             }, 0);
            //             this.getcampaigntemplates();
            //             this.schemename = '';
            //         } 
            //         else {
            //             this.modalloader = false;
            //             this.addschememodalsflag = false;
            //             // var errorMsg = data.message;
            //             // this.$toast.open({
            //             //     message: errorMsg,
            //             //     type: "error",
            //             //     duration: 3000,
            //             //     position: "top-right",
            //             // });
            //         }
            //     });
            // }
        },
        schemeReportModalOpen() {
            this.$router.push("/schemes/report");
        },
    },
};
</script>
<style scoped>
.modal-submit-btn.btn {
    background: #5266e5 0% 0% no-repeat padding-box;
    border-color: #5266e5;
    border-radius: 4px;
    letter-spacing: 0.26px;
    color: #ffffff;
    font-size: 13px;
    line-height: 20px;
    padding: 6px 35px;
    font-family: "AcuminPro-Regular";
}

.modal-submit-btn:focus {
    box-shadow: none;
}

.scheme-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;
}

.scheme-badge.status-open {
    background: #c8e6c9;
    color: #256029;
}

.scheme-badge.status-closed {
    background: #ffcdd2;
    color: #c63737;
}
</style>